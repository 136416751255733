<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{ $t('Список') }}</p>
                <div>
                    <crm-store-update-close
                    :permission="$options.name"
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <el-row > 
            <el-col :span="24">
                <div class="gc-card__body px-3 py-4">
                    <div class="app-form__group mb-0">
                        <div
                            class="
                                gc-card
                                rounded-sm
                                mb-4
                                p-relative
                                mr-3
                                px-3 py-3
                            "
                        >
                            <div class="">
                                <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered layout__initial">
                                    <thead>
                                        <tr>
                                            <th>
                                                Курьер
                                            </th>
                                            <th>
                                                Машина
                                            </th>
                                            <th>
                                                Количество грузов
                                            </th>
                                            <th>
                                                Количество заказов
                                            </th>
                                            <th>
                                                Дата создания
                                            </th>
                                            
                                        </tr>

                                    </thead>

                                    <transition-group name="flip-list" tag="tbody">
                                        <tr v-for="payment in inventory" :key="payment.id" class="cursor-pointer">
                                            <td>
                                                {{ payment.courier }}
                                            </td>
                                            <td>
                                                {{ payment.car }}
                                            </td>
                                            <td>
                                                {{ payment.quantity }}
                                            </td>
                                            <td>
                                                {{ payment.apply }}
                                            </td>
                                            <td>
                                                {{ payment.date }}
                                            </td>
                                        </tr>
                                    </transition-group>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
       
    </div>
</template>

<script>
import selectBoard from "@/components/inventory/select-deal-board";
import SelectCargo from "@/components/inventory/select-cargo";
export default {
data: () => ({
    formLabelAlign: {
        name: '',
        region: '',
        type: ''
    },
    mode: true,
    value1: '',
    createModal: false,
    value2: '',
    filterForm: {
            id: "",
            customer: "",
            paymentType:"",
            typePayment: "",
        
            time: "",
            sum: "",
            currency: "",
            created_at: "",
            updated_at: "",
    },
    columns: {
            id: {
                show: true,
                title: "№",
                sortable: true,
                column: "id",
            },

            customer: {
                show: true,
                title: "Типы расхода",
                sortable: true,
                column: "customer",
            },

            paymentType: {
                show: true,
                title: "Тип платежа",
                sortable: true,
                column: "paymentType",
            },

            typePayment: {
                show: true,
                title: "Тип оплаты",
                sortable: true,
                column: "typePayment",
            },

            

            time: {
                show: true,
                title: "Время",
                sortable: true,
                column: "time",
            },
            
            sum: {
                show: true,
                title: "Сумма",
                sortable: true,
                column: "sum",
            },


            currency: {
                show: true,
                title: "Валюта ",
                sortable: true,
                column: "currency",
            },

            created_at: {
                show: true,
                title: "Дата создания",
                sortable: true,
                column: "created_at",
            },

            updated_at: {
                show: true,
                title: "Дата изменена ",
                sortable: true,
                column: "updated_at",
            },

            settings: {
                show: true,
                title: "Профиль",
                sortable: false,
                column: "settings",
            },
    },
    tableData: [{
        date: 'Монитор Dell',
        name: '2',
        address: '3.8',
        code: '67804289323'
        }, {
        date: 'Монитор LG',
        name: '3',
        address: '2.8',
        code: '67804289323'
    },],
    inventory: [
                {
                    id: 1,
                    courier: "Azamat",
                    car: "Damas",
                    quantity: "12",
                    apply: "10",
                    date: "22.02.2022"
                },
                {
                    id: 2,
                    courier: "Sarvar",
                    car: "Spark",
                    quantity: "10",
                    apply: "10",
                    date: "22.02.2022"
                },
                {
                    id: 3,
                    courier: "Rashid",
                    car: "Labo",
                    quantity: "23",
                    apply: "10",
                    date: "22.02.2022"
                }
            ],
}),
components: {
    selectBoard,
    SelectCargo,
}
}
</script>

<style>

</style>
 